import logo from "./vader.png";
import "./App.css";
import TagManager from "react-gtm-module";
import { useEffect } from "react";

function App() {
  useEffect(() => {
    TagManager.initialize({ gtmId: "GTM-WZLDW5C" });
  }, []);

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>En construcción</p>
        <a
          className="App-link"
          href="https://github.com/hectormoreira"
          target="_blank"
          rel="noopener noreferrer"
        >
          GitHub
        </a>
        <a
          className="App-link"
          href="https://twitter.com/hectormoreirac"
          target="_blank"
          rel="noopener noreferrer"
        >
          Twitter
        </a>        
        <a
          className="App-link"
          href="https://www.linkedin.com/in/hector-hugo-moreira/"
          target="_blank"
          rel="noopener noreferrer"
        >
          LinkedIn
        </a>
      </header>
    </div>
  );
}

export default App;
